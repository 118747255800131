<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="3">
        <p>
          <span
            ><b>USER: </b>
            <span
              ><b>{{ this.currentUser }}</b></span
            ></span
          >
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="4">
        <p>
          <span><h3>DEALS MAIN</h3></span>
        </p>
      </v-col>
    </v-row>
    <div class="row">
      <div
        class="col-2"
        v-for="deals in userDeals"
        :key="deals.id"
        style="cursor: pointer"
      >
        <v-row>
          <v-col cols="12" sm="12">
            <v-img
              :src="require('../assets/Upload-Folder-icon.png')"
              contain
              class="img-class"
              @click="openUploads(deals.deal_id)"
            >
            </v-img>
          </v-col>
        </v-row>

        <p class="image_text" @click="openDealFolder(deals.deal_id)">
          {{ deals.deal_name }}
        </p>
      </div>
    </div>
  </v-container>
</template>

<script>
import CommonServices from "../service/CommonServices";

export default {
  data() {
    return {
      componentURL: "vwDeal/?archived=False",
      componentURL_broker: `DealBrokers/?archived=False&broker_id=${localStorage.getItem(
        "userid"
      )}`,
      componentURL_seller: `DealSellers/?archived=False&seller_id=${localStorage.getItem(
        "userid"
      )}`,
      componentURL_deal: `Deals/?archived=False&id=${localStorage.getItem(
        "DealID"
      )}`,
      componentURL_buyer: `DealBuyers/?archived=False&buyer_id=${localStorage.getItem(
        "userid"
      )}`,

      canpost: localStorage.getItem("canpost"),
      candelete: localStorage.getItem("candelete"),
      canput: localStorage.getItem("canput"),
      isReporter: localStorage.getItem("Reporter"),
      teamAccess: localStorage.getItem("teamAccess"),
      currentUser:
        localStorage.getItem("first_name") +
        " " +
        localStorage.getItem("last_name"),
      currentUserType: localStorage.getItem("role"),
      userid: localStorage.getItem("userid"),

      variants: [
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
      ],
      headerBgVariant: "danger",
      headerTextVariant: "light",
      bodyBgVariant: "light",
      bodyTextVariant: "dark",
      footerBgVariant: "danger",
      footerTextVariant: "dark",
      errorTitle: "",
      errorText: "",
      dlgError: false,

      valid: false,
      dialog: false,
      dialogDelete: false,
      deletedId: null,
      snackbar: false,
      snackColor: "",
      snackText: "",
      error: false,
      loading: false,
      editedIndex: -1,
      searchText: "",

      userDeals: {
        id: "",
        deal_name: "",
        filename: "",
        file: "",
      },

      selectedDeal: {
        id: "",
        deal_name: "",
      },

      userActivityItem: {
        user_id: localStorage.getItem("userid"),
        logdate: this.$moment(String(Date())).format("YYYY-MM-DD"),
        page: "DealsMain",
        action: "",
        deal_id: "",
      },
    };
  },

  created() {
    this.getUserDeals();
    this.logActivity("opened");
  },

  methods: {
    logActivity(activity, dealid) {
      this.userActivityItem.action = activity;
      this.userActivityItem.logdate = this.$moment(String(Date())).format(
        "YYYY-MM-DDThh:mm"
      );
      this.userActivityItem.deal_id = dealid;
      CommonServices.postData("UserActivityLog", this.userActivityItem)
        .then((response) => {
          console.log("UserActivityLog Response: ", response.id);
        })
        .catch((error) => {
          console.log("Post Activity Error: ", error);
        });
    },

    getUserDeals() {
      console.log("Current User: ", this.currentUser);
      console.log("Current User Type: ", this.currentUserType);
      if (this.currentUserType == 1) {
        this.getAdminDeals();
      }
      if (this.currentUserType == 2) {
        this.getBrokerDeals();
      }
      if (this.currentUserType == 3) {
        this.getSellerDeals();
      }
      if (this.currentUserType == 4) {
        this.getBuyerDeals();
      }
    },

    getAdminDeals() {
      CommonServices.getList(this.componentURL)
        .then((res) => {
          console.log("Admin Deals: ", res);
          this.userDeals = res.results;
          console.log("User Deals: ", this.userDeals);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getBrokerDeals() {
      CommonServices.getList(this.componentURL_broker)
        .then((res) => {
          console.log("Broker Deals: ", res);
          this.userDeals = res.results;
          console.log("User Deals: ", this.userDeals);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getBuyerDeals() {
      CommonServices.getList(this.componentURL_buyer)
        .then((res) => {
          console.log("Buyer Deals: ", res);
          this.userDeals = res.results;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getSellerDeals() {
      CommonServices.getList(this.componentURL_seller)
        .then((res) => {
          console.log("Seller Deals: ", res);
          this.userDeals = res.results;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    openDealFolder(dealid) {
      //First write any missing Deal Folders to the DealFolders table
      CommonServices.putData("Functions", { id: 4, param_one_number: dealid })
        .then((response) => {
          console.log("Put Function Response: ", response.id);
          setTimeout(() => {
            this.$router.push(`/DealFolders/${dealid}`);
          }, 500);
          this.logActivity("Opened Deal Folders", dealid);
        })
        .catch((error) => {
          console.log("Put Function Error: ", error);
        });
    },

    createDealFolders(dealid) {
      //First write any missing Deal Folders to the DealFolders table
      CommonServices.putData("Functions", { id: 4, param_one_number: dealid })
        .then((response) => {
          console.log("Create Deal Folders Response: ", response.id);
        })
        .catch((error) => {
          console.log("Put Function Error: ", error);
        });
    },

    openUploads(dealid) {
      //First write any missing Deal Folders to the DealFolders table
      CommonServices.putData("Functions", { id: 4, param_one_number: dealid })
        .then((response) => {
          console.log("Create Deal Folders Response: ", response.id);
        })
        .catch((error) => {
          console.log("Put Function Error: ", error);
        });
      localStorage.setItem("DealID", dealid);
      this.getDealData(dealid);
      //Next write any missing Uploads to the upload table
      CommonServices.putData("Functions", { id: 3 })
        .then((response) => {
          console.log("Put Function Response: ", response.id);
          this.logActivity("Opened Uploads", dealid);
        })
        .catch((error) => {
          console.log("Put Function Error: ", error);
        })
        .finally(() => {
          this.$router.push(`/Uploads/${dealid}`);
        });
    },

    getDealData() {
      CommonServices.getList(`Deals/?id=${localStorage.getItem("DealID")}`)
        .then((res) => {
          console.log("Selected Deal: ", res.results);
          this.selectedDeal = res.results;
          localStorage.setItem("Deal", this.selectedDeal[0].deal_name);
          console.log("Local Deal: ", localStorage.getItem("Deal"));
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.image_text {
  border: 1px solid black;
  margin: auto;
  padding: 12px;
  text-align: center;
  font-size: 10px;
  font-weight: bold;
}

.img-class {
  height: 90px !important;
  width: 90px !important;
}
</style>
